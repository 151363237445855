import React from 'react'
import { graphql } from 'gatsby'

import Content from '../components/Content'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import Accordion from '../components/Accordion'
import Popup from '../components/Popup'
import { FacebookProvider, Page } from 'react-facebook';



// Export Template for use in CMS preview.
export const ServicePageTemplate = ({
  title,
  subtitle,
  featuredImage,
  section1,
  section2,
  section3,
  section4,
  section5,
  section6,
  accordion
}) => (
  <main className="DefaultPage">
    <PageHeader
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    />
    <section className="section" />
    <div className="bungkus">
        <div className='bintang'/>
        <div className='bintang2'/>
        <div className='bintang3'/>
      <section className="bagian">
        <div className="container">
          <Popup>
            <Content source={section1} />
          </Popup>
          <div className="gbr WPimg" />
          <h3 className="taCenter">Wordpress</h3>
        </div>
      </section>
      <section className="bagian">
        <div className="container">
          <Popup>
            <Content source={section2} />
          </Popup>
          <div className="gbr JAMimg" />
          <h3 className="taCenter">JAMstack</h3>
        </div>
      </section>
      <section className="bagian">
        <div className="container">
          <Popup>
            <Content source={section3} />
          </Popup>
          <div className="gbr mobileimg" />
          <h3 className="taCenter">Mobile App</h3>
        </div>
      </section>
      <section className="bagian">
        <div className="container">
          <Popup>
            <Content source={section4} />
          </Popup>
          <div className="gbr tulisimg" />
          <h3 className="taCenter">Content Writing</h3>
        </div>
      </section>
      <section className="bagian">
        <div className="container">
          <Popup>
            <Content source={section5} />
          </Popup>
          <div className="gbr desainimg" />
          <h3 className="taCenter">Graphic Design</h3>
        </div>
      </section>
      <section className="bagian">
        <div className="container">
          <Popup>
            <Content source={section6} />
          </Popup>
          <div className="gbr kostumimg" />
          <h3 className="taCenter">Subscription</h3>
        </div>
      </section>
    </div>

    <section className="servisFAQ">
      <div className="container">
        <h2 className="taCenter">Frequently Asked Questions</h2>
        <Accordion items={accordion} />
      </div>
    </section>
    <section className="section" />
    <div className="fbm">
    <p align="center">
      <FacebookProvider appId="363762830961156">
        <Page href="https://www.facebook.com/philodotshop" width="500px" tabs="messages" adaptContainerWidth="true" smallHeader="true" height="280px" />
      </FacebookProvider>
    </p>
    </div>
  </main>
)

const ServicePage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ServicePageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)
export default ServicePage

export const pageQuery = graphql`
  query ServicePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        subtitle
        featuredImage
        section1
        section2
        section3
        section4
        section5
        section6
        accordion {
          title
          description
        }
      }
    }
  }
`
